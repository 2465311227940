"use client";

import { FC } from "react";
import Link from "next/link";
import { IAllStories, IBlogItem, IBrands, ICardMini, ICategory, IPrimaryBanner } from "@/shared/types/types";
import { li } from "@/utils/functions/li";
import LazyLoadSection from "@/shared/components/LazyLoadSection";
import LazyLoadUI from "@/shared/components/LazyLoadUI";
import StoriesSkeleton from "@/shared/components/skeleton-loader/tamplates/StoriesSkeleton";
import WeekendHitsSkeleton from "@/shared/components/skeleton-loader/tamplates/WeekendHitsSkeleton";
import CategoriesSkeleton from "@/shared/components/skeleton-loader/tamplates/CategoriesSkeleton";
import SwiperCardListSkeleton from "@/shared/components/skeleton-loader/tamplates/SwiperCardListSkeleton";
import SalesSkeleton from "@/shared/components/skeleton-loader/tamplates/SalesSkeleton";
import SeasonSkeleton from "@/shared/components/skeleton-loader/tamplates/SeasonSkeleton";
import BrandsSkeleton from "@/shared/components/skeleton-loader/tamplates/BrandsSkeleton";
import BlogsSkeleton from "@/shared/components/skeleton-loader/tamplates/BlogsSkeleton";
import { getHits, getPopularCategories, getRecommended, getSales, getSeason, getWeekendHits } from "@/utils/api/index-page";
import { getNew } from "@/utils/api/new";
import { getTopBrands } from "@/utils/api/brands";
import { getBlogs } from "@/utils/api/blogs";
import { getStories } from "@/utils/api/getStories";
import SkeletonLoader from "@/shared/components/skeleton-loader";
import styles from "./index.module.scss";
import dynamic from "next/dynamic";
import Banners from "@/primary/components/Banners";
import Stories from "@/shared/components/Stories";
import WeekItems from "@/primary/components/WeekHits";
// import Categories from "@/primary/components/Categories";
// import Picture from "@/shared/components/Picture";
// import Blog from "@/primary/components/Blog";
// import About from "@/primary/components/About";
// import Viewed from "@/shared/components/Viewed";
// import PopularSwiper from "@/shared/components/PopularSwiper";
// import SwiperCardList from "@/shared/components/SwiperCardList";
// import PersonalRecommended from "@/shared/components/PersonalRecommended";
// import BrandsPopular from "@/brands/components/BrandsPopular";
// import Season from "@/primary/components/Season";
// import Sales from "@/primary/components/Sales";

// const Banners = dynamic(() => import("@/primary/components/Banners"));
// const Stories = dynamic(() => import("@/shared/components/Stories"));
// const WeekItems = dynamic(() => import("@/primary/components/WeekHits"));
const Categories = dynamic(() => import("@/primary/components/Categories"));
const Picture = dynamic(() => import("@/shared/components/Picture"));
const Blog = dynamic(() => import("@/primary/components/Blog"));
const About = dynamic(() => import("@/primary/components/About"));
const Viewed = dynamic(() => import("@/shared/components/Viewed"));
const PopularSwiper = dynamic(() => import("@/shared/components/PopularSwiper"));
const SwiperCardList = dynamic(() => import("@/shared/components/SwiperCardList"));
const PersonalRecommended = dynamic(() => import("@/shared/components/PersonalRecommended"));
const BrandsPopular = dynamic(() => import("@/brands/components/BrandsPopular"));
const Season = dynamic(() => import("@/primary/components/Season"));
const Sales = dynamic(() => import("@/primary/components/Sales"));
interface IIndexComponent {
  banners: IPrimaryBanner[] | false;
  hits: Array<ICardMini> | false;
  recommended: Array<ICardMini> | false;
  categories: Array<ICategory> | false;
  season: any | false;
  newItems: Array<ICardMini> | false;
  sales: any | false;
  brands: Array<IBrands> | false;
  blogs: Array<IBlogItem> | false;
  weekendHits?: any | false;
  futureSales?: any | false;
  stories: IAllStories[] | false;
  isBot: boolean;
}
const Primary: FC<IIndexComponent> = props => {
  const {
    banners: initialBanners,
    hits: initialHits,
    recommended: initialRecommended,
    categories: initialCategories,
    season: initialSeason,
    newItems: initialNewItems,
    sales: initialSales,
    brands: initialBrands,
    blogs: initialBlogs,
    weekendHits: initialWeekendHits,
    stories: initialStories,
    futureSales,
    isBot
  } = props;
  return <main className={styles.main} id="main" data-sentry-component="Primary" data-sentry-source-file="index.tsx">
      <Banners banners={initialBanners} data-sentry-element="Banners" data-sentry-source-file="index.tsx" />
      {/*<LazyLoadSection*/}
      {/*  isBot={isBot}*/}
      {/*  initialData={initialBanners}*/}
      {/*  loadData={async () => {*/}
      {/*    const response = await getBanners();*/}
      {/*    return response && response.items ? response.items : [];*/}
      {/*  }}*/}
      {/*  skeleton={<BannersSkeleton />}*/}
      {/*>*/}
      {/*  {(banners) => (banners?.length ? <Banners banners={banners} /> : null)}*/}
      {/*</LazyLoadSection>*/}

      <LazyLoadSection isBot={isBot} initialData={initialStories} loadData={async () => {
      const response = await getStories();
      return response && response.items ? response.items : [];
    }} skeleton={<StoriesSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
        {stories => stories?.length ? <Stories stories={stories} /> : null}
      </LazyLoadSection>

      <div className="container">
        <LazyLoadSection isBot={isBot} initialData={initialWeekendHits} loadData={async () => {
        const response = await getWeekendHits();
        return response && response.items ? response.items : {
          items: []
        };
      }} skeleton={<WeekendHitsSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {weekendHits => {
          if (weekendHits && weekendHits.items && weekendHits.items.length) {
            return <WeekItems hits={weekendHits} />;
          }
          return null;
        }}
        </LazyLoadSection>

        <LazyLoadUI isBot={isBot} skeleton={<SkeletonLoader containerTag={"div"} width={"100%"} height={42} mt={16} mb={20} borderRadius={12} />} data-sentry-element="LazyLoadUI" data-sentry-source-file="index.tsx">
          <section>
            <PopularSwiper data-sentry-element="PopularSwiper" data-sentry-source-file="index.tsx" />
          </section>
        </LazyLoadUI>

        <LazyLoadSection isBot={isBot} initialData={initialCategories} loadData={async () => {
        const response = await getPopularCategories();
        return response && response.items ? response.items : [];
      }} skeleton={<CategoriesSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {categories => categories?.length ? <section className={styles.categories}>
                <h2 className={styles.title}>Популярные категории</h2>
                <Categories categories={categories} />
              </section> : null}
        </LazyLoadSection>

        <LazyLoadSection isBot={isBot} initialData={initialHits} loadData={async () => {
        const response = await getHits();
        return response && response.items && response.items.items ? response.items.items : [];
      }} skeleton={<SwiperCardListSkeleton title="Хиты продаж" />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {hits => hits?.length ? <SwiperCardList isVisibleTitle hasLink title={"Хиты продаж"} items={hits} link={"/collection/top-tovary/"} /> : null}
        </LazyLoadSection>

        <LazyLoadSection isBot={isBot} initialData={initialSales} loadData={async () => {
        const response = await getSales();
        return response && response.items ? response.items : [];
      }} skeleton={<SalesSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {sales => sales?.length ? <section className={styles.sales}>
                <h2 className={styles.title}>
                  <Link prefetch={false} href={`/sales`}>
                    Акции
                  </Link>
                  <Link prefetch={false} className={styles.titleLink} href={`/sales`}>
                    смотреть
                  </Link>
                </h2>
                <Sales sales={sales} />
              </section> : null}
        </LazyLoadSection>

        <LazyLoadSection isBot={isBot} initialData={initialNewItems} loadData={async () => {
        const response = await getNew("type=swiper");
        return response && response.items && response.items.items ? response.items.items : [];
      }} skeleton={<SwiperCardListSkeleton title="Новинки" />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {newItems => newItems?.length ? <SwiperCardList isVisibleTitle hasLink title={"Новинки"} items={newItems} link={"/new"} /> : null}
        </LazyLoadSection>

        <LazyLoadSection isBot={isBot} initialData={initialSeason} loadData={async () => {
        const response = await getSeason();
        return response && response.items ? response.items : [];
      }} skeleton={<SeasonSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {season => season?.length ? <section className={styles.season}>
                <h2 className={"title"}>Готовимся к сезону</h2>
                <Season season={season} />
              </section> : null}
        </LazyLoadSection>

        <LazyLoadSection isBot={isBot} initialData={initialRecommended} loadData={async () => {
        const response = await getRecommended();
        return response && response.items && response.items.items ? response.items.items : [];
      }} skeleton={<SwiperCardListSkeleton title="Рекомендуем" />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {recommended => recommended?.length ? <SwiperCardList isVisibleTitle hasLink={false} title={"Рекомендуем"} items={recommended} /> : null}
        </LazyLoadSection>

        <LazyLoadUI isBot={isBot} skeleton={<SkeletonLoader containerTag={"div"} width={"100%"} height={160} mt={20} mb={20} borderRadius={12} />} data-sentry-element="LazyLoadUI" data-sentry-source-file="index.tsx">
          <section className={styles.nested}>
            <h2 className={"visually-hidden"}>Продавай оружие с ОхотАктив</h2>
            <Link prefetch={false} href={"/catalog/comission"} data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <Picture className={styles.nestedBanner} alt={"Продавай оружие с ОхотАктив"} width={1450} height={154} desktop={li("comission-banner-index.webp")} mobile={li("comission-banner-mob-index.webp")} data-sentry-element="Picture" data-sentry-source-file="index.tsx" />
            </Link>
          </section>
        </LazyLoadUI>

        <PersonalRecommended data-sentry-element="PersonalRecommended" data-sentry-source-file="index.tsx" />

        <LazyLoadSection isBot={isBot} initialData={initialBrands} loadData={async () => {
        const response = await getTopBrands();
        return response && response.items ? response.items : [];
      }} skeleton={<BrandsSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {brands => brands?.length ? <BrandsPopular brands={brands} /> : null}
        </LazyLoadSection>

        <LazyLoadSection isBot={isBot} initialData={initialBlogs} loadData={async () => {
        const response = await getBlogs("per_page=6");
        return response && response.items ? response.items : [];
      }} skeleton={<BlogsSkeleton />} data-sentry-element="LazyLoadSection" data-sentry-source-file="index.tsx">
          {blogs => blogs?.length ? <section className={styles.blog}>
                <h2 className={styles.title}>
                  <Link prefetch={false} href={`/blog`}>
                    Блог
                  </Link>
                  <Link prefetch={false} className={styles.titleLink} href={`/blog`}>
                    смотреть
                  </Link>
                </h2>
                <Blog blogs={blogs} />
              </section> : null}
        </LazyLoadSection>

        <Viewed data-sentry-element="Viewed" data-sentry-source-file="index.tsx" />
        <About data-sentry-element="About" data-sentry-source-file="index.tsx" />
      </div>
    </main>;
};
export default Primary;